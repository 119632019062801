import { autoinject, computedFrom, inject } from "aurelia-framework";
import {Router } from "aurelia-router";
  import { Routes } from "routes";
  import { EventAggregator } from "aurelia-event-aggregator";

  
@autoinject
export class CollectionId {

  private tabs: number = 0;
  
  constructor( private router: Router, private ea: EventAggregator,) {}
}
