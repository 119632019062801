// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/collection_id_banner.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/svg/collection_id_banner_mobile.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/checkfill.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/svg/heart_empty.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/img/currency/bitcoin/bsv.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./collection_id.scss"></require>
  <section id="collection_id" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <div class="flex-grow"></div>
      <!-- <p>SHARE</p> -->
    </div>
    <div class="banner flex-column">
      <div class="background flex-column">
        <img id="background" class="hidden_on_mobile" src="${___HTML_LOADER_IMPORT_1___}">
        <img class="hidden_on_desktop" src="${___HTML_LOADER_IMPORT_2___}"> 
      </div>
      <div class="logo flex-column">
        <img id="logo" src="https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&">
      </div>
    </div>
    <div class="collection_creator flex-row">
      <span>MyBanka// Collection name? Creator?</span>
      <img src="${___HTML_LOADER_IMPORT_3___}">
    </div>
    <!-- <p class="title">Bored Dogs Christmas Collection</p> -->
    <p class="description">DESCRIPTION Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    <div class="search_flip_container flex-row">
      <form class="inputs">
        <div>
          <input value.bind="search_term" type="text" i18n="[placeholder]home.recherche" />
          <i class="fas fa-search"></i>
        </div>
      </form>
      <ul class="flex-row" if.bind="!isReturnBack">
        <li t="assets.collection_home.marketplace" class.bind="tabs == 0 ? 'active' : ''" click.delegate="tabs = 0"></li>
        <li t="assets.collection_home.favorites" class.bind="tabs == 1 ? 'active' : ''" click.delegate="tabs = 1"></li>
      </ul>
    </div>
    <div class="collection_list wrap">
      <div class="item flex-column">
        <div class="img_heart container flex-column">
          <div class="bg_heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}">
            <p>100</p>
          </div>
          <img id="item" src="https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&">
        </div>
        <div class="info flex-column">
          <span class="info number">#1</span>
          <span class="info name">Item name</span>
          <div class="price flex-row">
            <img class="currency" src="${___HTML_LOADER_IMPORT_5___}">
            <p class="price">0.38095</p>
          </div>
        </div>
      </div>
      <div class="item flex-column">
        <div class="img_heart container flex-column">
          <div class="bg_heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}">
            <p>100</p>
          </div>
          <img id="item" src="https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&">
        </div>
        <div class="info flex-column">
          <span class="info number">#1</span>
          <span class="info name">Item name</span>
          <div class="price flex-row">
            <img class="currency" src="${___HTML_LOADER_IMPORT_5___}">
            <p class="price">0.38095</p>
          </div>
        </div>
      </div>
      <div class="item flex-column">
        <div class="img_heart container flex-column">
          <div class="bg_heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}">
            <p>100</p>
          </div>
          <img id="item" src="https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&">
        </div>
        <div class="info flex-column">
          <span class="info number">#1</span>
          <span class="info name">Item name</span>
          <div class="price flex-row">
            <img class="currency" src="${___HTML_LOADER_IMPORT_5___}">
            <p class="price">0.38095</p>
          </div>
        </div>
      </div>
      <div class="item flex-column">
        <div class="img_heart container flex-column">
          <div class="bg_heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}">
            <p>100</p>
          </div>
          <img id="item" src="https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&">
        </div>
        <div class="info flex-column">
          <span class="info number">#1</span>
          <span class="info name">Item name</span>
          <div class="price flex-row">
            <img class="currency" src="${___HTML_LOADER_IMPORT_5___}">
            <p class="price">0.38095</p>
          </div>
        </div>
      </div>
      <div class="item flex-column">
        <div class="img_heart container flex-column">
          <div class="bg_heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}">
            <p>100</p>
          </div>
          <img id="item" src="https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&">
        </div>
        <div class="info flex-column">
          <span class="info number">#1</span>
          <span class="info name">Item name</span>
          <div class="price flex-row">
            <img class="currency" src="${___HTML_LOADER_IMPORT_5___}">
            <p class="price">0.38095</p>
          </div>
        </div>
      </div>
      <div class="item flex-column">
        <div class="img_heart container flex-column">
          <div class="bg_heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}">
            <p>100</p>
          </div>
          <img id="item" src="https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&">
        </div>
        <div class="info flex-column">
          <span class="info number">#1</span>
          <span class="info name">Item name</span>
          <div class="price flex-row">
            <img class="currency" src="${___HTML_LOADER_IMPORT_5___}">
            <p class="price">0.38095</p>
          </div>
        </div>
      </div>
      <div class="item flex-column">
        <div class="img_heart container flex-column">
          <div class="bg_heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_4___}">
            <p>100</p>
          </div>
          <img id="item" src="https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&">
        </div>
        <div class="info flex-column">
          <span class="info number">#1</span>
          <span class="info name">Item name</span>
          <div class="price flex-row">
            <img class="currency" src="${___HTML_LOADER_IMPORT_5___}">
            <p class="price">0.38095</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;